<template>
    <div data-task :style="type != 'tasks' ? '' : task.section || task.parent ? 'display: none' : ''"
        :class="subtasks.length > 0 ? '' : 'has_subtask'" :data-id="task._id" :data-name="task.name"
        :data-section="task.section || ''">
        <div class="task" :class="task.done ? 'done' : ''">
            <div class="top-list__btn" v-if="subtasks.length" @click="show_subtasks = !show_subtasks">
                <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                    :class="show_subtasks ? 'active' : ''">
                    <path
                        d="M0.576117 0.99729C0.70958 0.997029 0.838921 1.04351 0.941688 1.12867L4.00335 3.68766L7.07071 1.22006C7.12914 1.17261 7.19637 1.13718 7.26854 1.1158C7.3407 1.09442 7.41638 1.08751 7.49122 1.09547C7.56607 1.10343 7.6386 1.12611 7.70465 1.16219C7.77071 1.19828 7.82898 1.24706 7.87611 1.30574C7.92356 1.36417 7.95899 1.4314 7.98037 1.50356C8.00176 1.57573 8.00866 1.65141 8.0007 1.72625C7.99274 1.8011 7.97006 1.87363 7.93398 1.93968C7.89789 2.00573 7.84911 2.064 7.79043 2.11114L4.3632 4.87006C4.261 4.95407 4.13279 5 4.00049 5C3.86818 5 3.73998 4.95407 3.63777 4.87006L0.210546 2.01403C0.152707 1.96608 0.104896 1.90719 0.0698514 1.84073C0.0348067 1.77427 0.0132165 1.70156 0.00631857 1.62674C-0.00057888 1.55193 0.0073514 1.47649 0.0296535 1.40474C0.0519562 1.333 0.0881925 1.26635 0.136289 1.20864C0.189442 1.14311 0.256451 1.09017 0.332499 1.05363C0.408547 1.01709 0.491746 0.997848 0.576117 0.99729Z"
                        fill="white"></path>
                </svg>
            </div>
            <div style="
                                                background-color: #fff;
                                                display: flex;
                                		        align-items: center;
                                                height: 45px;
                                            " :style="subtasks.length ? 'width: calc(100% - 25px);' : 'width: 100%;'">
                <label>
                    <input type="checkbox" @change="doneTask" v-model="task.done">
                    <span class="check-style"></span>
                </label>
                <div class="link" @touchstart="startDownPress" @touchend="upPress" @touchmove="onTouchMove">
                    <input v-if="changeTaskName" v-focus type="text" class="task__name" maxlength="160" v-model="task_name"
                        @blur="saveTaskName" @keypress.enter="saveTaskName">
                    <button v-else class="task__name">{{ task.name }}</button>
                    <div>
                        <span class="date" v-if="task.assign.date" v-html="assignDateTask(task.assign.date)"></span>
                        <span class="unread" v-if="unread > 0">{{ unread }}</span>
                    </div>
                </div>
                <div class="priority" :class="task.priority"></div>
            </div>
        </div>
        <Tasks v-if="subtasks.length && show_subtasks" :tasks="subtasks"></Tasks>
    </div>
</template>

<script>

import Tasks from '@/components/tasks/Tasks'
import { mapActions, mapMutations } from "vuex"

const focus = {
    inserted(el) {
        el.focus()
    },
}

export default {
    name: 'Task',
    directives: { focus },
    data: () => ({
        task_name: '',
        change_taskName: false,
        show_subtasks: false,
        menu_opened: false,
        pressTime: '',
        startTime: 0,
        duration: 0,
        isShowMenu: false,
        isChangePosition: false,
    }),
    props: ['task', 'type', 'move'],
    components: {
        Tasks
    },
    computed: {
        changeTaskName: {
            get() {
                return this.change_taskName
            },
            set(value) {
                this.change_taskName = value
                if (value == true) {
                    this.task_name = this.task.name
                    setTimeout(() => {
                        document.querySelector('.link input').focus()
                    }, 1000)
                }
            }
        },
        subtasks() {
            if (!this.$store.state.tasks.subtask.filter(e => e.parent == this.task._id).length) return []
            return this.$store.state.tasks.subtask.filter(e => e.parent == this.task._id).sort((a, b) => {
                return a.order - b.order
            })
        },
        unread() {
            return this.$store.state.comments.unreaded_messages_in_tasks[this.task._id]
        }
    },
    methods: {
        ...mapMutations([
            "setMove"
        ]),

        ...mapActions([
            "socket_completed_task",
            "socket_rename_task",
            "socket_delete_task",
            "socket_add_task"
        ]),
        startDownPress() {
            this.isShowMenu = true;
            this.startTime = Date.now();
            this.pressTime = setTimeout(() => {
                if (!this.move && this.isShowMenu && !this.isChangePosition) {
                    this.$root.$emit('task_menu', { task_id: this.task._id })
                    this.$root.$emit('disable_menu_click', true)
                }
            }, 400)
        },
        upPress() {
            if (!this.move && !this.isChangePosition) {
                this.isShowMenu = false;
                const duration = Date.now() - this.startTime;

                if (!this.move && duration < 400) {
                    this.$router.push('/project/' + this.task.project_id + '/task/' + this.task._id)
                }
            }
            this.$root.$emit('disable_menu_click', false)
            clearTimeout(this.pressTime)
            this.isChangePosition = false;
        },
        onTouchMove() {
            if (!this.isChangePosition) {
                this.isChangePosition = true;
            }
        },
        assignDate(date) {
            if (date) {
                if (!isNaN(date)) {
                    date = new Date(+date).toISOString()
                }

                const today = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                ).getTime()

                const yestarday = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 1
                ).getTime()

                const tomorrow = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + 1
                ).getTime()

                const date_from_comment = new Date(
                    new Date(date).getFullYear(),
                    new Date(date).getMonth(),
                    new Date(date).getDate()
                ).getTime()

                if (date_from_comment == today) { return "Today" }
                else if (date_from_comment == yestarday) { return "Yesterday" }
                else if (date_from_comment == tomorrow) { return "Tomorrow" }
                else {
                    if (new Date().getFullYear() == new Date(date).getFullYear()) {
                        let d = new Intl.DateTimeFormat("en-GB", {
                            day: "numeric",
                            month: "short",
                        }).format(new Date(date))
                        d = d.split(" ")
                        return d[1] == 'Sept' ? 'Sep' + " " + d[0] : d[1] + " " + d[0]
                    } else {
                        let d = new Intl.DateTimeFormat("en-GB", {
                            day: "numeric",
                            month: "short",
                        }).format(new Date(date)) + ' ' + new Date(date).getFullYear()
                        d = d.split(" ")
                        return d[1] == 'Sept' ? 'Sep' + " " + d[0] + ", " + d[2] : d[1] + " " + d[0] + ", " + d[2]
                    }
                }
            }
        },
        assignDateTask(date) {

            if (isNaN(date)) {
                date = new Date(date).getTime()
            }

            if (isNaN(this.assign_date)) {
                this.assign_date = new Date(this.assign_date).getTime()
            }

            if (this.assign_date) {
                if (this.assignDate(this.assign_date) == 'Today' || this.assignDate(this.assign_date) == 'Yesterday') {
                    return `<p style="color: #FF8686">${this.assignDate(this.assign_date)}</p>`
                } else {
                    if (this.assign_date > Date.now()) {
                        return `<p style="color: #489CDC">${this.assignDate(this.assign_date)}</p>`
                    } else {
                        return `<p style="color: #FF8686">${this.assignDate(this.assign_date)}</p>`
                    }
                }
            } else {
                if (this.assignDate(date) == 'Today' || this.assignDate(date) == 'Yesterday') {
                    return `<p style="color: #FF8686">${this.assignDate(date)}</p>`
                } else {
                    if (date > Date.now()) {
                        return `<p style="color: #489CDC">${this.assignDate(date)}</p>`
                    } else {
                        return `<p style="color: #FF8686">${this.assignDate(date)}</p>`
                    }
                }
            }
        },

        doneTask() {
            this.socket_completed_task({
                complete: this.task.done,
                task_id: this.task._id,
                project_id: this.$route.params.project_id,
            })
        },
        saveTaskName() {
            this.task_name = this.task_name.slice(0, 160)
            if (this.task.new) {
                if (this.task_name == '') {
                    return this.socket_delete_task({
                        project_id: this.task.project_id,
                        task_id: this.task._id
                    })
                }
                this.changeTaskName = false
                this.socket_add_task({
                    name: this.task_name.trim(),
                    project_id: this.task.project_id,
                    order: this.task.order,
                    _id: this.task._id,
                    parent: this.task.parent,
                    assign: {
                        user_id: false,
                        date: ''
                    }
                })
            } else {
                if (this.task_name == '') {
                    return document.querySelector('.link input').focus()
                }
                if (this.task_name == this.task.name) {
                    return this.changeTaskName = false
                }
                this.changeTaskName = false
                this.socket_rename_task({
                    project_id: this.task.project_id,
                    task_id: this.task._id,
                    name: this.task_name
                })
            }
        }
    },
    mounted() {
        this.$root.$on('openSubtask', (task_id) => {
            if (task_id == this.task._id) {
                this.show_subtasks = true
            }
        })
        this.$root.$on('task_menu_closed', ({ task_id }) => {
            this.menu_opened = false
        })
        this.$root.$on('task_rename', (task_id) => {
            if (task_id == this.task._id) {
                this.changeTaskName = true
            }
        })
    },
}

</script>